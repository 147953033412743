<template>
    <div>
        <p><b class="fee-policy-title">Policy Overview</b></p>
        <p class="fee-policy-text">EAE has instituted a multi-tiered fee reduction policy, comprising eight distinct
            levels. The allocation of each student to a particular reduction level is determined by a formula that takes
            into account the Country/Region Class and the Tuition Tier.</p>
        <br />
        <div align="center">
            <table class="fee-policy-table" border="0" cellspacing="0" cellpadding="0">
                <tbody>
                    <tr>
                        <th class="header-cell" width="160">
                            <p>Reduction Level</p>
                        </th>
                        <th class="header-cell" width="300">Reduction Amount (USD)</th>
                    </tr>
                    <tr>
                        <td>
                            <p>1</p>
                        </td>
                        <td>
                            <p>{{ getReductionPrice(1) }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>2</p>
                        </td>
                        <td>
                            <p>{{ getReductionPrice(2) }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>3</p>
                        </td>
                        <td>
                            <p>{{ getReductionPrice(3) }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>4</p>
                        </td>
                        <td>
                            <p>{{ getReductionPrice(4) }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>5</p>
                        </td>
                        <td>
                            <p>{{ getReductionPrice(5) }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>6</p>
                        </td>
                        <td>
                            <p>{{ getReductionPrice(6) }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>7</p>
                        </td>
                        <td>
                            <p>{{ getReductionPrice(7) }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>8</p>
                        </td>
                        <td>
                            <p>{{ getReductionPrice(8) }}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br />
        <p><b class="fee-policy-title">Calculation of Reduction Level</b></p>
        <p class="fee-policy-text">The formula for ascertaining the reduction level is as follows:</p>
        <p class="fee-policy-text" align="center">Reduction Level = MIN(Country/Region Class + Tuition Tier - 1, 8) +
            Targeted Offer</p>
        <br />
        <p><b class="fee-policy-title">For Tuition Tier 9:</b></p>
        <p class="fee-policy-text" align="center">Reduction Level = MIN(Country/Region Class + Tuition Tier - 4, 8) +
            Targeted Offer</p>
        <br />
        <p class="fee-policy-text">In the formulas, “Targeted Offer” represents additional support provided to specific
            regions during specific time periods, based on the overall operational plan. Its value is either 0 or 1.</p>
        <br />
        <p><b class="fee-policy-title">Example</b></p>
        <p class="fee-policy-text">Consider a student whose country falls under Class 1, whose annual tuition fee is
            Tier 3, and there’s no Targeted Offer. According to the formula for Tuition Tiers higher than 9:</p>
        <p class="fee-policy-text" align="center">Reduction Level = MIN(Country/Region Class + Tuition Tier - 1, 8) +
            Targeted Offer</p>
        <br />
        <p class="fee-policy-text">The student's Reduction Level would be calculated as MIN(1 + 3 - 1, 8) + 0, which
            equals 3.</p>
        <br />
        <p class="fee-policy-text">So, how do we determine a student's Country/Region Class and the applicable Tuition
            Tier? Further in this document, you will find tables that outline the classifications for Country/Region
            Classes and Tuition Tiers. For instance, if a student is from Singapore, the
            table indicates that the Country/Region Class is 1. If this student's annual tuition fee is 2280 USD, they
            would fall under Tuition Tier 4.</p>
        <br />
        <p><b class="fee-policy-title">Country/Region Class</b></p>
        <p class="fee-policy-text" style="margin-bottom: 10px;">A country or region is categorized into one of four classes based on its GDP, average
            income per person, and other cultural factors.</p>
        <div align="center">
            <table class="fee-policy-table" border="1" cellspacing="0" cellpadding="0"
                style="border-collapse:collapse;border:none;">
                <thead>
                    <tr>
                        <th class="header-cell" width="60">
                            <p>Class</p>
                        </th>
                        <th class="header-cell">Countries / Regions (In alphabetic order)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <p>1</p>
                        </td>
                        <td style="padding: 10px;">
                            <p align="left">China, Hong Kong (China), Israel, Japan, Qatar, Saudi Arabia, Singapore,
                                South Korea, United Arab Emirates, United States</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>2</p>
                        </td>
                        <td style="padding: 10px;">
                            <p align="left">Australia, Austria, Belgium, Bermuda, Canada, Cayman Islands, Denmark,
                                Finland, France, Germany, Greenland, Iceland, India, Indonesia, Iraq, Ireland, Italy,
                                Kazakhstan, Liechtenstein, Luxembourg, Macau (China), Malaysia, Mexico, Monaco,
                                Netherlands, Norway,
                                Russia, San Marino, Spain, Sweden, Switzerland, Taiwan (China), Thailand, Türkiye,
                                United Kingdom</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>3</p>
                        </td>
                        <td style="padding: 10px;">
                            <p align="left">Albania, Andorra, Anguilla, Antigua and Barbuda, Argentina, Armenia, Aruba,
                                Azerbaijan, Bahamas, Bahrain, Bangladesh, Barbados, Belarus, Belize, Bosnia and
                                Herzegovina, Botswana, Brazil, British Virgin Islands, Brunei, Bulgaria, Chile, Cook
                                Islands, Costa
                                Rica, Croatia, Cuba, Curacao, Cyprus, Czech Republic, Dominica, Dominican Republic,
                                Ecuador, El Salvador, Equatorial Guinea, Estonia, Fiji, French Polynesia, Gabon,
                                Georgia, Greece, Grenada, Guatemala, Guyana, Hungary, Iran, Jamaica, Kosovo, Kuwait,
                                Latvia, Lebanon,
                                Libya, Lithuania, Maldives, Malta, Marshall Islands, Mauritius, Mongolia, Montenegro,
                                Montserrat, Nauru, New Caledonia, New Zealand, North Macedonia, Oman, Pakistan, Palau,
                                Panama, Paraguay, Philippines, Poland, Portugal, Puerto Rico, Romania, Saint Kitts and
                                Nevis, Saint
                                Lucia, Saint Vincent and the Grenadines, Serbia, Seychelles, Sint Maarten, Slovakia,
                                Slovenia, South Africa, Suriname, Tonga, Trinidad and Tobago, Turkmenistan, Turks and
                                Caicos Islands, Tuvalu, Uruguay, Vietnam</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>4</p>
                        </td>
                        <td style="padding: 10px;">
                            <p align="left">Afghanistan, Algeria, Angola, Benin, Bhutan, Bolivia, Burkina Faso, Burundi,
                                Cambodia, Cameroon, Cape Verde, Central African Republic, Chad, Colombia, Comoros,
                                Djibouti, DR Congo, Egypt, Eritrea, Eswatini, Ethiopia, Gambia, Ghana, Guinea, Guinea
                                Bissau, Haiti,
                                Honduras, Ivory Coast, Jordan, Kenya, Kiribati, Kyrgyzstan, Laos, Lesotho, Liberia,
                                Madagascar, Malawi, Mali, Mauritania, Micronesia, Moldova, Morocco, Mozambique, Myanmar,
                                Namibia, Nepal, Nicaragua, Niger, Nigeria, North Korea, Palestine, Papua New Guinea,
                                Peru, Republic
                                of the Congo, Rwanda, Samoa, Sao Tome and Principe, Senegal, Sierra Leone, Solomon
                                Islands, Somalia, South Sudan, Sri Lanka, Sudan, Syria, Tajikistan, Tanzania, Timor
                                Leste, Togo, Tunisia, Uganda, Ukraine, Uzbekistan, Vanuatu, Venezuela, Yemen, Zambia,
                                Zimbabwe</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br />
        <p><b class="fee-policy-title">Tuition Tier</b></p>
        <p class="fee-policy-text">The Tuition Tier is stratified according to the annual tuition fees levied by the
            school or educational institution. Nine tiers are established, delineated by the following criteria:</p>
        <ul class="fee-policy-list">
            <li>Tier 1: Exceeding 11,000 USD per year</li>
            <li>Tier 2: Exceeding 6,000 USD per year</li>
            <li>Tier 3: Exceeding 3,000 USD per year</li>
            <li>Tier 4: Exceeding 1,500 USD per year</li>
            <li>Tier 5: Exceeding 1,100 USD per year</li>
            <li>Tier 6: Exceeding 750 USD per year</li>
            <li>Tier 7: Exceeding 350 USD per year</li>
            <li>Tier 8: Less than 350 USD per year but greater than 0</li>
            <li>Tier 9: No tuition fees charged</li>
        </ul>
        <br />
        <p class="fee-policy-text">Students who have benefited from financial aid, scholarships, or other subsidies that
            have resulted in a reduction of their tuition fees below the listed rate may submit an application for
            reclassification into a lower Tuition Tier. Applicants are required to furnish
            appropriate documentation evidencing their actual tuition fee payments.</p>

    </div>
</template>

<script>
export default {
    name: "index",
    props: {
        price_reductions: {
            type: Array,
            default: []
        }
    },
    data() {
        return {

            policy_info: {

            }
        }
    },
    methods: {
        getReductionPrice(index) {
            if (this.price_reductions.length > 0) {
                for (let i = 0; i < this.price_reductions.length; i++) {
                    let item = this.price_reductions[i]
                    if (item.level == (index)) {
                        return item.reduction_price
                    }
                }
            }
            return 0
        }
    },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    display: none;
}

.fee-policy-title {
    font-family: "Arial", sans-serif;
    color: #ff6450;
}

.fee-policy-text {
    margin-top: 10px;
    line-height: 18px;
    font-family: "Arial", sans-serif;
    color: #404040;
}

.fee-policy-table {
    border-collapse: collapse;

}

.fee-policy-table td,
.fee-policy-table th {
    border: solid windowtext 1.0pt;
    text-align: center;
}

.fee-policy-table .header-cell {
    padding: 10px 5px;
    font-family: "Arial", sans-serif;
    color: #ff6450;
}

.fee-policy-list li {
    margin-left: 15px;
    color: #404040;
    margin-top: 5px;
    list-style-type: disc
}
</style>